<div class="content">
  <!-- Accodion FAQs -->
  <p class="text-center">
    <span>
      <span style="font-size:24px">
        <strong>Preguntas Frecuentes </strong>
      </span>
    </span>
  </p>

  <p>&nbsp;</p>

  <!-- BNA , BNA ESPECIALES, MI MOTO-->
  <div *ngIf="option === 0" class="accordion">

    <!-- Section -->
    <div class="card">
      <div class="card-header p-0" id="group-1">
        <h2 class="mb-0">
          <button style="font-size: 12px;"
            class="btn btn-link btn-block text-left text-dark" type="button"
            data-toggle="collapse" data-target="#collapse-1"
            aria-expanded="false" aria-controls="collapse-1">
            {{ '¿Qué es Tienda BNA?' | uppercase }}
            <i class="fa fa-chevron-down"
              style="opacity:.2; float: right; margin-top: 4px;"></i>
          </button>
        </h2>
      </div>

      <div id="collapse-1" class="collapse" aria-labelledby="group-1">
        <div class="card-body">
          <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
            <span style="font-size:12px">
              <span style="background-color:white">
                <span style="font-family:Tahoma,Geneva,sans-serif">
                  <span style="color:#333333">
                    Es el Marketplace oficial del Banco de la Nación Argentina.
                    Es la Tienda Digital donde comerciantes de toda la Nación
                    Argentina, ofrecen sus productos para ser adquiridos por
                    consumidores del todo el País.
                  </span>
                </span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
      <div class="card-header p-0" id="group-2">
        <h2 class="mb-0">
          <button style="font-size: 12px;"
            class="btn btn-link btn-block text-left text-dark" type="button"
            data-toggle="collapse" data-target="#collapse-2"
            aria-expanded="false" aria-controls="collapse-2">
            {{ '¿Debo estar registrado para comprar?' | uppercase }}
            <i class="fa fa-chevron-down"
              style="opacity:.2; float: right; margin-top: 4px;"></i>
          </button>
        </h2>
      </div>

      <div id="collapse-2" class="collapse" aria-labelledby="group-2">
        <div class="card-body">
          <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
            <span style="font-size:12px">
              <span style="background-color:white">
                <span style="font-family:Tahoma,Geneva,sans-serif">
                  <span style="color:#333333">
                    Sí. Para poder realizar compras en nuestra tienda digital
                    debes registrarte por única vez. Una vez que estes
                    registrado solo debes ingresar con tu usuario y clave.
                  </span>
                </span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
      <div class="card-header p-0" id="group-3">
        <h2 class="mb-0">
          <button style="font-size: 12px;"
            class="btn btn-link btn-block text-left text-dark" type="button"
            data-toggle="collapse" data-target="#collapse-3"
            aria-expanded="false" aria-controls="collapse-3">
            {{ '¿Cómo me registro?' | uppercase }}
            <i class="fa fa-chevron-down"
              style="opacity:.2; float: right; margin-top: 4px;"></i>
          </button>
        </h2>
      </div>

      <div id="collapse-3" class="collapse" aria-labelledby="group-3">
        <div class="card-body">
          <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
            <span style="font-size:12px">
              <span style="background-color:white">
                <span style="font-family:Tahoma,Geneva,sans-serif">
                  <span style="color:#333333">
                    Para registrarte tenés que dirigirte al icono <img
                      src="../../../assets/images/bna-faqs/figura 1.jpg"
                      alt="faqs"
                      style="width:20px; height: 25px; margin-bottom: 18px;">
                    ubicado en el margen superior derecho. Se abrirá una
                    ventana, allí deberás tildar el reCAPTCHA y luego se
                    activará el botón “Registrarme” que deberás cliquear para
                    iniciar con el registro. Se abrirá una ventana para
                    completar con tus datos personales y preguntas de validación
                    de identidad.
                    <br>
                    <br>
                    ¡Y listo ya estas Registrado!
                  </span>
                </span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
      <div class="card-header p-0" id="group-4">
        <h2 class="mb-0">
          <button style="font-size: 12px;"
            class="btn btn-link btn-block text-left text-dark" type="button"
            data-toggle="collapse" data-target="#collapse-4"
            aria-expanded="false" aria-controls="collapse-4">
            {{ 'Olvide mi contraseña ¿Qué debo hacer?' | uppercase }}
            <i class="fa fa-chevron-down"
              style="opacity:.2; float: right; margin-top: 4px;"></i>
          </button>
        </h2>
      </div>

      <div id="collapse-4" class="collapse" aria-labelledby="group-4">
        <div class="card-body">
          <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
            <span style="font-size:12px">
              <span style="background-color:white">
                <span style="font-family:Tahoma,Geneva,sans-serif">
                  <span style="color:#333333">
                    Si olvidaste tu contraseña, no te preocupes aquí te contamos
                    los pasos a seguir para obtener una nueva.
                    <br>
                    Deberás ubicarte en el icono <img
                      src="../../../assets/images/bna-faqs/figura 1.jpg"
                      alt="faqs"
                      style="width:20px; height: 25px; margin-bottom: 18px;">.
                    luego hacer click sobre recuperar contraseña:
                    <br>
                    Deberás ubicarte en el icono <img
                      src="../../../assets/images/bna-faqs/figura 1.jpg"
                      alt="faqs"
                      style="width:20px; height: 25px; margin-bottom: 18px;">.
                    luego hacer click sobre recuperar contraseña:
                    <br>
                    <br>
                    <img src="../../../assets/images/bna-faqs/figura 2.jpg"
                      alt="faqs">
                    <br>
                    Allí debés colocar el mail con el que te registraste y hacer
                    click sobre “Recuperar”
                    <br>
                    Allí debés colocar el mail con el que te registraste y hacer
                    click sobre “Recuperar”
                    <br>
                    <img src="../../../assets/images/bna-faqs/figura 3.jpg"
                      alt="faqs">
                    <br>
                    <img src="../../../assets/images/bna-faqs/figura 3.jpg"
                      alt="faqs">
                    <br>
                    <br>
                    Te llegará un correo electrónico a la casilla ingresada con
                    una nueva contraseña generada automáticamente para que
                    puedas ingresar, te recomendamos cambiarla apenas ingreses.
                    <br>
                    <br>
                    Recordá siempre chequear tu casilla de Spam en caso de no
                    recibir el correo en tu casilla principal.
                  </span>
                </span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
      <div class="card-header p-0" id="group-5">
        <h2 class="mb-0">
          <button style="font-size: 12px;"
            class="btn btn-link btn-block text-left text-dark" type="button"
            data-toggle="collapse" data-target="#collapse-5"
            aria-expanded="false" aria-controls="collapse-5">
            {{ '¿Cómo compro en Tienda BNA?' | uppercase }}
            <i class="fa fa-chevron-down"
              style="opacity:.2; float: right; margin-top: 4px;"></i>
          </button>
        </h2>
      </div>

      <div id="collapse-5" class="collapse" aria-labelledby="group-5">
        <div class="card-body">
          <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
            <span style="font-size:12px">
              <span style="background-color:white">
                <span style="font-family:Tahoma,Geneva,sans-serif">
                  <span style="color:#333333">
                    Para poder comprar en la tienda primero deberás loguearte,
                    en caso de que no cuentes con usuario podrás registrarte.
                    Una vez registrado podés seleccionar el producto y hacer
                    click en la opción de 'Agregar a carrito' o 'Comprar ahora'.
                    <br>
                    <br>
                    Si seleccionaste agregar carrito para finalizar la compra
                    debes seleccionar el carrito de la parte superior derecha y
                    finalizar la operación.
                  </span>
                </span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
      <div class="card-header p-0" id="group-6">
        <h2 class="mb-0">
          <button style="font-size: 12px;"
            class="btn btn-link btn-block text-left text-dark" type="button"
            data-toggle="collapse" data-target="#collapse-6"
            aria-expanded="false" aria-controls="collapse-6">
            {{'No encuentro un producto que estaba publicado ¿Qué puedo hacer?'
            | uppercase}}
            <i class="fa fa-chevron-down"
              style="opacity:.2; float: right; margin-top: 4px;"></i>
          </button>
        </h2>
      </div>

      <div id="collapse-6" class="collapse" aria-labelledby="group-6">
        <div class="card-body">
          <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
            <span style="font-size:12px">
              <span style="background-color:white">
                <span style="font-family:Tahoma,Geneva,sans-serif">
                  <span style="color:#333333">
                    Es probable que el producto que estas buscando haya agotado
                    su stock de venta, por tal motivo ya no se encuentra
                    disponible en la Tienda para la venta.
                  </span>
                </span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
      <div class="card-header p-0" id="group-7">
        <h2 class="mb-0">
          <button style="font-size: 12px;"
            class="btn btn-link btn-block text-left text-dark" type="button"
            data-toggle="collapse" data-target="#collapse-7"
            aria-expanded="false" aria-controls="collapse-7">
            {{ 'Mi Carrito ¿Cómo se Utiliza?' | uppercase }}
            <i class="fa fa-chevron-down"
              style="opacity:.2; float: right; margin-top: 4px;"></i>
          </button>
        </h2>
      </div>

      <div id="collapse-7" class="collapse" aria-labelledby="group-7">
        <div class="card-body">
          <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
            <span style="font-size:12px">
              <span style="background-color:white">
                <span style="font-family:Tahoma,Geneva,sans-serif">
                  <span style="color:#333333">
                    Nuestra tienda cuenta con un Carrito de compras al que podés
                    ingresar los productos que querés y pagar todo junto. Solo
                    debés hacer lo siguiente:
                    <br>
                    <br>
                    1- Elegís tu producto (deberás verificar toda la información
                    dentro de los apartados de descripción, ficha técnica y
                    envío)
                    <br>
                    <br>
                    2- Si todo es de tu agrado, seleccionas Agregar al carrito.
                    <br>
                    <br>
                    <img src="../../../assets/images/bna-faqs/figura 9.jpg"
                      alt="faqs">
                    <br>
                    <br>
                    3- Luego elegir la opción de Continuar comprando.
                    <br>
                    <br>
                    <img src="../../../assets/images/bna-faqs/figura 10.jpg"
                      alt="faqs">
                    <br>
                    <br>
                    Tenés que hacer esto con cada producto que quieras comprar.
                    Cuando quieras finalizar la compra, solo tenés que
                    seleccionar el botón de Finalizar compra.
                    <br>
                    <br>
                    <img src="../../../assets/images/bna-faqs/figura 11.jpg"
                      alt="faqs">
                  </span>
                </span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
      <div class="card-header p-0" id="group-8">
        <h2 class="mb-0">
          <button style="font-size: 12px;"
            class="btn btn-link btn-block text-left text-dark" type="button"
            data-toggle="collapse" data-target="#collapse-8"
            aria-expanded="false" aria-controls="collapse-8">
            {{'Mi Carrito, si me arrepiento de algún producto seleccionado ¿Cómo lo elimino?'
            | uppercase}}
            <i class="fa fa-chevron-down"
              style="opacity:.2; float: right; margin-top: 4px;"></i>
          </button>
        </h2>
      </div>

      <div id="collapse-8" class="collapse" aria-labelledby="group-8">
        <div class="card-body">
          <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
            <span style="font-size:12px">
              <span style="background-color:white">
                <span style="font-family:Tahoma,Geneva,sans-serif">
                  <span style="color:#333333">
                    Para eliminar un producto ya seleccionado en tu carrito
                    debes seguir los siguientes pasos:
                    <br>
                    <br>
                    1- Ingresá a tu carrito, en la parte superior derecha de la
                    página. <img
                      src="../../../assets/images/bna-faqs/figura 12.jpg"
                      alt="faqs" style="height: 30px">
                    <br>
                    <br>
                    2- Selecciona el/los productos que deseas eliminar
                    <br>
                    <br>
                    3- Una vez seleccionado, deberás hacer clic en el tacho de
                    basura. <img
                      src="../../../assets/images/bna-faqs/figura 13.jpg"
                      alt="faqs" style="height: 30px">
                    <br>
                    <br>
                    4- Listo, el producto quedo eliminado de tu carrito
                    <br>
                    <br>
                    <img src="../../../assets/images/bna-faqs/figura 14.jpg"
                      alt="faqs">
                  </span>
                </span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
      <div class="card-header p-0" id="group-9">
        <h2 class="mb-0">
          <button style="font-size: 12px;"
            class="btn btn-link btn-block text-left text-dark" type="button"
            data-toggle="collapse" data-target="#collapse-9"
            aria-expanded="false" aria-controls="collapse-9">
            {{'Tenía un producto seleccionado en mi carrito y ya no lo veo ¿Qué pudo haber pasado?'
            | uppercase}}
            <i class="fa fa-chevron-down"
              style="opacity:.2; float: right; margin-top: 4px;"></i>
          </button>
        </h2>
      </div>

      <div id="collapse-9" class="collapse" aria-labelledby="group-9">
        <div class="card-body">
          <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
            <span style="font-size:12px">
              <span style="background-color:white">
                <span style="font-family:Tahoma,Geneva,sans-serif">
                  <span style="color:#333333">
                    Puede haber sucedido que alguno de los productos agregados a
                    tu compra ya no tenga disponibilidad por estar agotados y
                    por esta razón hayan desaparecido de tu carrito.
                  </span>
                </span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
      <div class="card-header p-0" id="group-10">
        <h2 class="mb-0">
          <button style="font-size: 12px;"
            class="btn btn-link btn-block text-left text-dark" type="button"
            data-toggle="collapse" data-target="#collapse-10"
            aria-expanded="false" aria-controls="collapse-10">
            {{'¿Cuáles son los métodos de pago?' | uppercase}}
            <i class="fa fa-chevron-down"
              style="opacity:.2; float: right; margin-top: 4px;"></i>
          </button>
        </h2>
      </div>

      <div id="collapse-10" class="collapse" aria-labelledby="group-10">
        <div class="card-body">
          <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
            <span style="font-size:12px">
              <span style="background-color:white">
                <span style="font-family:Tahoma,Geneva,sans-serif">
                  <span style="color:#333333">
                    Los medios de pagos disponible en Tienda BNA son; Tarjetas
                    de débito, crédito, prepagas (Plan Argentina Programa)
                    emitidas por el Banco de la Nación Argentina y app BNA+ o
                    MODO.
                    <br>
                    <br>
                    Podes también abonar con cupones adquiridos en la aplicación
                    BNA+, del programa de beneficios.
                  </span>
                </span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
      <div class="card-header p-0" id="group-11">
        <h2 class="mb-0">
          <button style="font-size: 12px;"
            class="btn btn-link btn-block text-left text-dark" type="button"
            data-toggle="collapse" data-target="#collapse-11"
            aria-expanded="false" aria-controls="collapse-11">
            {{'¿Cómo obtengo mis cupones para pagar en Tienda BNA?' |
            uppercase}}
            <i class="fa fa-chevron-down"
              style="opacity:.2; float: right; margin-top: 4px;"></i>
          </button>
        </h2>
      </div>

      <div id="collapse-11" class="collapse" aria-labelledby="group-11">
        <div class="card-body">
          <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
            <span style="font-size:12px">
              <span style="background-color:white">
                <span style="font-family:Tahoma,Geneva,sans-serif">
                  <span style="color:#333333">
                    Podes obtener cupones canjeando tus puntos acumulados del
                    programa de beneficios a través de <a
                      href="https://www.bna.com.ar/Downloads/InstructivoBNAmasCLIENTES.pdf"
                      target="_blank">BNA+</a> o también en forma telefónica,
                    comunicándote al 0810 666 1515 de lunes a viernes de 8 a 20
                    hs.
                  </span>
                </span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
      <div class="card-header p-0" id="group-12">
        <h2 class="mb-0">
          <button style="font-size: 12px;"
            class="btn btn-link btn-block text-left text-dark" type="button"
            data-toggle="collapse" data-target="#collapse-12"
            aria-expanded="false" aria-controls="collapse-12">
            {{ ' ¿Qué tipo de factura emiten?' | uppercase }}
            <i class="fa fa-chevron-down"
              style="opacity:.2; float: right; margin-top: 4px;"></i>
          </button>
        </h2>
      </div>

      <div id="collapse-12" class="collapse" aria-labelledby="group-12">
        <div class="card-body">
          <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
            <span style="font-size:12px">
              <span style="background-color:white">
                <span style="font-family:Tahoma,Geneva,sans-serif">
                  <span style="color:#333333">
                    Nuestro sitio está dirigido al consumidor final, por ese
                    motivo emitimos solamente Factura B, para todas las
                    operaciones realizadas en la página.
                    <br>
                    <br>
                    Esta factura se envía de forma electrónica al mail con el
                    cual se efectuó la compra y también será entregada de forma
                    física cuando el producto llegue a destino.
                  </span>
                </span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
      <div class="card-header p-0" id="group-13">
        <h2 class="mb-0">
          <button style="font-size: 12px;"
            class="btn btn-link btn-block text-left text-dark" type="button"
            data-toggle="collapse" data-target="#collapse-13"
            aria-expanded="false" aria-controls="collapse-13">
            {{'¿Cómo recibo la factura?' | uppercase}}
            <i class="fa fa-chevron-down"
              style="opacity:.2; float: right; margin-top: 4px;"></i>
          </button>
        </h2>
      </div>

      <div id="collapse-13" class="collapse" aria-labelledby="group-13">
        <div class="card-body">
          <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
            <span style="font-size:12px">
              <span style="background-color:white">
                <span style="font-family:Tahoma,Geneva,sans-serif">
                  <span style="color:#333333">
                    Te enviaremos la factura correspondiente a tu compra al mail
                    que dejaste asentado en el momento de la registración, en
                    caso de no recibirla te sugerimos que verifiques tu casilla
                    de SPAM es probable que ingrese en esa carpeta. La factura
                    física será entregada junto con la entrega del producto.
                  </span>
                </span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
      <div class="card-header p-0" id="group-14">
        <h2 class="mb-0">
          <button style="font-size: 12px;"
            class="btn btn-link btn-block text-left text-dark" type="button"
            data-toggle="collapse" data-target="#collapse-14"
            aria-expanded="false" aria-controls="collapse-14">
            {{'Aún no recibí mi factura ¿Qué hago?' | uppercase}}
            <i class="fa fa-chevron-down"
              style="opacity:.2; float: right; margin-top: 4px;"></i>
          </button>
        </h2>
      </div>

      <div id="collapse-14" class="collapse" aria-labelledby="group-14">
        <div class="card-body">
          <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
            <span style="font-size:12px">
              <span style="background-color:white">
                <span style="font-family:Tahoma,Geneva,sans-serif">
                  <span style="color:#333333">
                    Si aun no has recibido la factura vía email, ni entregada
                    con el producto en el domicilio, por favor ponte en contacto
                    con nuestro centro de ayuda de tienda BNA a través del
                    siguiente link <a href="https://www.tiendabna.com.ar/ayuda"
                      target="_blank">https://www.tiendabna.com.ar/ayuda</a>
                    para que podamos asesorarte.
                  </span>
                </span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
      <div class="card-header p-0" id="group-15">
        <h2 class="mb-0">
          <button style="font-size: 12px;"
            class="btn btn-link btn-block text-left text-dark" type="button"
            data-toggle="collapse" data-target="#collapse-15"
            aria-expanded="false" aria-controls="collapse-15">
            {{'Realice una compra ¿Cómo puedo saber cuándo llega?' | uppercase}}
            <i class="fa fa-chevron-down"
              style="opacity:.2; float: right; margin-top: 4px;"></i>
          </button>
        </h2>
      </div>

      <div id="collapse-15" class="collapse" aria-labelledby="group-15">
        <div class="card-body">
          <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
            <span style="font-size:12px">
              <span style="background-color:white">
                <span style="font-family:Tahoma,Geneva,sans-serif">
                  <span style="color:#333333">
                    Confirmada tu compra recibirás un correo proveniente del
                    comercio al cual has realizado la compra, en él encontraras
                    el número de orden, descripción del producto, número de guía
                    y nombre de la empresa logística contratada para hacer
                    entrega de tu compra. Con el número de guía podrás realizar
                    seguimiento de tu pedido.
                    <br>
                    <br>
                    Tené en cuenta que este correo puede llegarte en los
                    próximos 5 días hábiles de realizada la compra.
                  </span>
                </span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
      <div class="card-header p-0" id="group-16">
        <h2 class="mb-0">
          <button style="font-size: 12px;"
            class="btn btn-link btn-block text-left text-dark" type="button"
            data-toggle="collapse" data-target="#collapse-16"
            aria-expanded="false" aria-controls="collapse-16">
            {{'Realice una compra y no me llegó el número el número de guía ¿Qué hago?'
            | uppercase}}
            <i class="fa fa-chevron-down"
              style="opacity:.2; float: right; margin-top: 4px;"></i>
          </button>
        </h2>
      </div>

      <div id="collapse-16" class="collapse" aria-labelledby="group-16">
        <div class="card-body">
          <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
            <span style="font-size:12px">
              <span style="background-color:white">
                <span style="font-family:Tahoma,Geneva,sans-serif">
                  <span style="color:#333333">
                    Recordá que el mail puede llegarte hasta 5 días hábiles
                    posteriores a la fecha de compra.
                    <br>
                    <br>
                    Sugerimos verificar tu casilla de correo no deseados por si
                    ingreso en dicha casilla. En caso de que hayas verificado y
                    no se encuentre el mail te pedimos que nos contactes al
                    siguiente link <a href="https://www.tiendabna.com.ar/ayuda"
                      ref="_blank">https://www.tiendabna.com.ar/ayuda</a> para
                    que podamos asesorarte.
                  </span>
                </span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
      <div class="card-header p-0" id="group-17">
        <h2 class="mb-0">
          <button style="font-size: 12px;"
            class="btn btn-link btn-block text-left text-dark" type="button"
            data-toggle="collapse" data-target="#collapse-17"
            aria-expanded="false" aria-controls="collapse-17">
            {{'Cómo puedo saber, ¿cuál será el costo de envío?' | uppercase}}
            <i class="fa fa-chevron-down"
              style="opacity:.2; float: right; margin-top: 4px;"></i>
          </button>
        </h2>
      </div>

      <div id="collapse-17" class="collapse" aria-labelledby="group-17">
        <div class="card-body">
          <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
            <span style="font-size:12px">
              <span style="background-color:white">
                <span style="font-family:Tahoma,Geneva,sans-serif">
                  <span style="color:#333333">
                    El costo de envío se calcula según el peso de la compra en
                    relación con el destino de entrega, podrás ver el valor
                    cuando ingreses los datos de tu domicilio, antes de
                    finalizar la compra. También podrás calcular el precio del
                    envío, a la hora de verificar los datos de una publicación,
                    lo verás reflejado en el margen derecho de la pantalla.
                    <br>
                    <br>
                    <img src="../../../assets/images/bna-faqs/figura 15.jpg"
                      alt="faqs">
                    <br>
                    <br>
                    Existen diferentes tipos de resultados que podrás visualizar
                    al seleccionar Calcular:
                    <br>
                    <br>
                    - El proveedor se pondrá en contacto para coordinar la
                    entrega y el costo de envío, (Recuerda que, en el resumen de
                    tu compra figure $0 en el monto de envío, es el proveedor
                    quien te informará dicho importe)
                    <br>
                    <br>
                    <img src="../../../assets/images/bna-faqs/figura 16.jpg"
                      alt="faqs">
                    <br>
                    <br>
                    - El envío tiene un costo, el mismo varía dependiendo del
                    proveedor
                    <br>
                    <br>
                    <img src="../../../assets/images/bna-faqs/figura 17.jpg"
                      alt="faqs">
                    <br>
                    <br>
                    - El envío es gratuito
                    <br>
                    Existen diferentes tipos de resultados que podrás visualizar
                    al seleccionar Calcular:
                    <br>
                    <img src="../../../assets/images/bna-faqs/figura 18.jpg"
                      alt="faqs">
                  </span>
                </span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
      <div class="card-header p-0" id="group-18">
        <h2 class="mb-0">
          <button style="font-size: 12px;"
            class="btn btn-link btn-block text-left text-dark" type="button"
            data-toggle="collapse" data-target="#collapse-18"
            aria-expanded="false" aria-controls="collapse-18">
            {{'Compre un producto en el que la entrega se coordina con el proveedor ¿Qué significa?'
            | uppercase}}
            <i class="fa fa-chevron-down"
              style="opacity:.2; float: right; margin-top: 4px;"></i>
          </button>
        </h2>
      </div>

      <div id="collapse-18" class="collapse" aria-labelledby="group-18">
        <div class="card-body">
          <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
            <span style="font-size:12px">
              <span style="background-color:white">
                <span style="font-family:Tahoma,Geneva,sans-serif">
                  <span style="color:#333333">
                    Significa que el producto se entrega con una logística
                    propia del comercio asociado y no mediante un operador
                    tercerizado. Para estos casos el comercio se pondrá en
                    contacto con vos para coordinar la entrega o bien, te
                    enviará por correo el detalle de cómo se procederá para el
                    envío.
                  </span>
                </span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
      <div class="card-header p-0" id="group-19">
        <h2 class="mb-0">
          <button style="font-size: 12px;"
            class="btn btn-link btn-block text-left text-dark" type="button"
            data-toggle="collapse" data-target="#collapse-19"
            aria-expanded="false" aria-controls="collapse-19">
            {{'¿Cuánto demora en llegar el envío a domicilio?' | uppercase}}
            <i class="fa fa-chevron-down"
              style="opacity:.2; float: right; margin-top: 4px;"></i>
          </button>
        </h2>
      </div>

      <div id="collapse-19" class="collapse" aria-labelledby="group-19">
        <div class="card-body">
          <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
            <span style="font-size:12px">
              <span style="background-color:white">
                <span style="font-family:Tahoma,Geneva,sans-serif">
                  <span style="color:#333333">
                    En Tienda BNA queremos que estés lo más informado acerca de
                    tu envío, por eso es que cuando tu pedido se encuentre
                    despachado te enviaremos un mail con todos los datos del mismo.
                    <br>
                    <br>
                    - El tiempo de entrega es hasta 15 días hábiles Para CABA,
                    GBA y resto del país.
                    <br>
                    <br>
                    - Para muebles y línea blanca es hasta 15 días hábiles para
                    CABA y GBA y de 20 días hábiles para el interior del país.
                    <br>
                    <br>
                    Si ya cuentas con el número de guía podrás consultar en
                    tiempo real el estado de envío del pedido y los tiempos
                    estimados de entrega.
                  </span>
                </span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
      <div class="card-header p-0" id="group-20">
        <h2 class="mb-0">
          <button style="font-size: 12px;"
            class="btn btn-link btn-block text-left text-dark" type="button"
            data-toggle="collapse" data-target="#collapse-20"
            aria-expanded="false" aria-controls="collapse-20">
            {{'Realice compras de productos de diferentes comercios ¿Cómo será la entrega?'
            | uppercase}}
            <i class="fa fa-chevron-down"
              style="opacity:.2; float: right; margin-top: 4px;"></i>
          </button>
        </h2>
      </div>

      <div id="collapse-20" class="collapse" aria-labelledby="group-20">
        <div class="card-body">
          <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
            <span style="font-size:12px">
              <span style="background-color:white">
                <span style="font-family:Tahoma,Geneva,sans-serif">
                  <span style="color:#333333">
                    Al realizar compras de comercios diferentes, cada producto
                    será despachado desde depósitos diferentes por lo que debes
                    recibir tanto mail con número de guía como productos
                    comprados para que puedas realizar seguimiento de cada uno
                    de ellos.
                  </span>
                </span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
      <div class="card-header p-0" id="group-21">
        <h2 class="mb-0">
          <button style="font-size: 12px;"
            class="btn btn-link btn-block text-left text-dark" type="button"
            data-toggle="collapse" data-target="#collapse-21"
            aria-expanded="false" aria-controls="collapse-21">
            {{'Si no estoy presente en el momento de la entrega del producto ¿Quién puede recibirlo? ¿Puedo asignar una persona para recibirlo?'
            | uppercase}}
            <i class="fa fa-chevron-down"
              style="opacity:.2; float: right; margin-top: 4px;"></i>
          </button>
        </h2>
      </div>

      <div id="collapse-21" class="collapse" aria-labelledby="group-21">
        <div class="card-body">
          <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
            <span style="font-size:12px">
              <span style="background-color:white">
                <span style="font-family:Tahoma,Geneva,sans-serif">
                  <span style="color:#333333">
                    Si es posible asignar un tercero para la recepción del
                    producto comprado, siempre y cuando cumpla con los
                    requisitos necesarios para la entrega.
                    <br>
                    <br>
                    Los requisitos son:
                    <br>
                    <br>
                    -Ser persona mayor de 18 años con documentación propia
                    <br>
                    -Documento que acredite identidad del titular de la compra
                    <br>
                    -Tarjeta con la cual se realizó la compra
                    <br>
                    <br>
                    El documento de identidad del titular de la compra como la
                    tarjeta con la que se realizó pueden presentarse de forma
                    Física o digital
                  </span>
                </span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
      <div class="card-header p-0" id="group-22">
        <h2 class="mb-0">
          <button style="font-size: 12px;"
            class="btn btn-link btn-block text-left text-dark" type="button"
            data-toggle="collapse" data-target="#collapse-22"
            aria-expanded="false" aria-controls="collapse-22">
            {{'¿Qué debo presentar para que me hagan la entrega del producto?' |
            uppercase}}
            <i class="fa fa-chevron-down"
              style="opacity:.2; float: right; margin-top: 4px;"></i>
          </button>
        </h2>
      </div>

      <div id="collapse-22" class="collapse" aria-labelledby="group-22">
        <div class="card-body">
          <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
            <span style="font-size:12px">
              <span style="background-color:white">
                <span style="font-family:Tahoma,Geneva,sans-serif">
                  <span style="color:#333333">
                    El servicio de correo contempla la entrega del producto a
                    quien acredite:
                    <br>
                    <br>
                    -Documento de identidad del titular de la compra
                    <br>
                    -Tarjeta con la cual se realizó la compra
                  </span>
                </span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
      <div class="card-header p-0" id="group-23">
        <h2 class="mb-0">
          <button style="font-size: 12px;"
            class="btn btn-link btn-block text-left text-dark" type="button"
            data-toggle="collapse" data-target="#collapse-23"
            aria-expanded="false" aria-controls="collapse-23">
            {{'¿Qué pasa si no hay nadie en el domicilio cuando llega el envío?'
            | uppercase}}
            <i class="fa fa-chevron-down"
              style="opacity:.2; float: right; margin-top: 4px;"></i>
          </button>
        </h2>
      </div>

      <div id="collapse-23" class="collapse" aria-labelledby="group-23">
        <div class="card-body">
          <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
            <span style="font-size:12px">
              <span style="background-color:white">
                <span style="font-family:Tahoma,Geneva,sans-serif">
                  El correo realiza la visita al domicilio en dos oportunidades
                  para efectuar la entrega. En caso de no encontrarse nadie en
                  ambos momentos dejara un aviso de visita, con un número de
                  contacto para que puedas coordinar el retiro del producto en
                  alguna sede del operador logístico dentro de un limite de
                  tiempo. Cumplido el tiempo indicado en el aviso el producto
                  volverá al vendedor.
                </span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
      <div class="card-header p-0" id="group-24">
        <h2 class="mb-0">
          <button style="font-size: 12px;"
            class="btn btn-link btn-block text-left text-dark" type="button"
            data-toggle="collapse" data-target="#collapse-24"
            aria-expanded="false" aria-controls="collapse-24">
            {{'Compre un producto y necesito cambiarlo /devolverlo ¿Qué tengo que hacer?'
            | uppercase}}
            <i class="fa fa-chevron-down"
              style="opacity:.2; float: right; margin-top: 4px;"></i>
          </button>
        </h2>
      </div>

      <div id="collapse-24" class="collapse" aria-labelledby="group-24">
        <div class="card-body">
          <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
            <span style="font-size:12px">
              <span style="background-color:white">
                <span style="font-family:Tahoma,Geneva,sans-serif">
                  <span style="color:#333333">
                    En Tienda BNA queremos que tu experiencia sea la mejor, por
                    eso si el producto que compraste tuvo algún inconveniente te
                    invitamos que nos brindes más información a través del
                    siguiente link <a href="https://www.tiendabna.com.ar/ayuda"
                      target="_blank">https://www.tiendabna.com.ar/ayuda</a>
                    seleccionando “botón arrepentimiento” tienes 10 días
                    posteriores a la entrega para que puedan gestionarte el
                    cambio, esta gestión no tiene costo extra.
                    <br>
                    <br>
                    En caso de que haya transcurrido un lapso mayor a los 10
                    días, te sugerimos contactarte con la garantía del producto
                    en caso de presentar algún desperfecto.
                  </span>
                </span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
      <div class="card-header p-0" id="group-25">
        <h2 class="mb-0">
          <button style="font-size: 12px;"
            class="btn btn-link btn-block text-left text-dark" type="button"
            data-toggle="collapse" data-target="#collapse-25"
            aria-expanded="false" aria-controls="collapse-25">
            {{'¿Cuáles son los motivos por los que puedo solicitar un cambio?' |
            uppercase}}
            <i class="fa fa-chevron-down"
              style="opacity:.2; float: right; margin-top: 4px;"></i>
          </button>
        </h2>
      </div>

      <div id="collapse-25" class="collapse" aria-labelledby="group-25">
        <div class="card-body">
          <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
            <span style="font-size:12px">
              <span style="background-color:white">
                <span style="font-family:Tahoma,Geneva,sans-serif">
                  <span style="color:#333333">
                    Los motivos por los cuales podés gestionar el cambio o la
                    devolución de un producto son los siguientes:
                    <br>
                    <br>
                    - Producto defectuoso - Me equivoqué en la compra - Recibí
                    algo que no pedí (o un producto diferente)
                    <br>
                    <br>
                    No se aceptan cambios y devoluciones de productos usados o
                    sin sus accesorios o envoltorio original (cajas, plásticos,
                    manuales, etc). Los cambios se encuentran sujetos a
                    disponibilidad de stock. Se deberá realizar la devolución
                    del producto original para recibir el nuevo producto o el
                    reembolso correspondiente.
                    <br>
                    <br>
                    Pasados los 10 días posteriores a la recepción o retiro del
                    producto por parte del comprador, el producto tiene
                    garantía, puedes contactarte con la misma para efectuar la
                    reparación o cambio.
                  </span>
                </span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
      <div class="card-header p-0" id="group-26">
        <h2 class="mb-0">
          <button style="font-size: 12px;"
            class="btn btn-link btn-block text-left text-dark" type="button"
            data-toggle="collapse" data-target="#collapse-26"
            aria-expanded="false" aria-controls="collapse-26">
            {{' Realice la devolución de un producto, ¿Cómo lo veo reflejado en mi cuenta?'
            | uppercase}}
            <i class="fa fa-chevron-down"
              style="opacity:.2; float: right; margin-top: 4px;"></i>
          </button>
        </h2>
      </div>

      <div id="collapse-26" class="collapse" aria-labelledby="group-26">
        <div class="card-body">
          <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
            <span style="font-size:12px">
              <span style="background-color:white">
                <span style="font-family:Tahoma,Geneva,sans-serif">
                  <span style="color:#333333">
                    En caso de que debamos proceder con una devolución por
                    anulación de la compra, la misma se realizará a través del
                    mismo medio utilizado para abonar la compra. Es decir, si la
                    compra fue abonada con tarjeta de crédito el reembolso se
                    hará en esa misma tarjeta y se verá reflejado en el próximo
                    resumen de cuenta. Si la compra fue abonada con tarjeta de
                    débito la misma se reflejará a partir de las 72hs de
                    confirmado la anulación de la compra.
                  </span>
                </span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
      <div class="card-header p-0" id="group-27">
        <h2 class="mb-0">
          <button style="font-size: 12px;"
            class="btn btn-link btn-block text-left text-dark" type="button"
            data-toggle="collapse" data-target="#collapse-27"
            aria-expanded="false" aria-controls="collapse-27">
            {{'¿Cómo funciona la garantía de un producto?' | uppercase}}
            <i class="fa fa-chevron-down"
              style="opacity:.2; float: right; margin-top: 4px;"></i>
          </button>
        </h2>
      </div>

      <div id="collapse-27" class="collapse" aria-labelledby="group-27">
        <div class="card-body">
          <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
            <span style="font-size:12px">
              <span style="background-color:white">
                <span style="font-family:Tahoma,Geneva,sans-serif">
                  <span style="color:#333333">
                    Todos los productos comercializados en Tienda BNA, cuentan
                    con garantía de fábrica, en la publicación de cada uno de
                    ellos podrás ver los plazos dependiendo del artículo. Para
                    poder hacer uso de la misma debes asistir al servicio
                    técnico oficial de la marca con la factura de compra.
                  </span>
                </span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <!-- <div class="card">
        <div class="card-header p-0" id="group-28">
            <h2 class="mb-0">
                <button style="font-size: 12px;" class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapse-28" aria-expanded="false" aria-controls="collapse-28">
                  {{'¿Qué son las garantías extendidas?' | uppercase}}
                <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
                    </button>
            </h2>
        </div>

        <div id="collapse-28" class="collapse" aria-labelledby="group-28" >
            <div class="card-body">
                <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
                  <span style="font-size:12px">
                    <span style="background-color:white">
                      <span style="font-family:Tahoma,Geneva,sans-serif">
                        Para conocer más sobre las garantías extendidas ingresar a este <a href="https://www.tiendabna.com.ar/garantias-extendidas" target="_blank">LINK</a>
                      </span>
                    </span>
                  </span>
              </p>
            </div>
        </div>
      </div> -->
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
      <div class="card-header p-0" id="group-29">
        <h2 class="mb-0">
          <button style="font-size: 12px;"
            class="btn btn-link btn-block text-left text-dark" type="button"
            data-toggle="collapse" data-target="#collapse-29"
            aria-expanded="false" aria-controls="collapse-29">
            {{'¿Cómo funciona la contratación de un Seguro en Tienda BNA?' |
            uppercase}}
            <i class="fa fa-chevron-down"
              style="opacity:.2; float: right; margin-top: 4px;"></i>
          </button>
        </h2>
      </div>

      <div id="collapse-29" class="collapse" aria-labelledby="group-29">
        <div class="card-body">
          <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
            <span style="font-size:12px">
              <span style="background-color:white">
                <span style="font-family:Tahoma,Geneva,sans-serif">
                  Podes contratar tu seguro todo riesgo para productos
                  seleccionados de Tienda BNA. Luego de agregar tu producto al
                  carrito y presionar “Finalizar Compra” podrás seleccionar la
                  opción “Añadir Seguro”. Allí verás el monto por mes del valor
                  del seguro. Solo podrás asegurar productos desde el proceso de
                  checkout, no es posible asegurar otros productos adquiridos
                  anteriormente o que no sean de la tienda.
                  <br>
                  <br>
                  Si seleccionaste asegurar tu producto, deberás completar los
                  datos solicitados y luego te llegarán por mail los datos de la
                  póliza.
                  <br>
                  <br>
                  Tu seguro estará vigente a partir del quinto día hábil desde
                  realizada la compra. Si necesitas utilizarlo deberás
                  contactarte al 0800-888-9908 para denunciar el siniestro.
                  <br>
                  <br>
                  La vigencia del seguro es de 12 meses y tiene renovación
                  automática.
                  <br>
                  <br>
                  En caso de que desees dar de baja el seguro deberás
                  contactarte a 0800-888-9908.
                </span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
      <div class="card-header p-0" id="group-30">
        <h2 class="mb-0">
          <button style="font-size: 12px;"
            class="btn btn-link btn-block text-left text-dark" type="button"
            data-toggle="collapse" data-target="#collapse-30"
            aria-expanded="false" aria-controls="collapse-30">
            {{'¿Es seguro comprar en Tienda BNA?' | uppercase}}
            <i class="fa fa-chevron-down"
              style="opacity:.2; float: right; margin-top: 4px;"></i>
          </button>
        </h2>
      </div>

      <div id="collapse-30" class="collapse" aria-labelledby="group-30">
        <div class="card-body">
          <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
            <span style="font-size:12px">
              <span style="background-color:white">
                <span style="font-family:Tahoma,Geneva,sans-serif">
                  En Tienda BNA le damos mucha importancia a la transparencia de
                  nuestras operaciones y cuidamos tu experiencia de compra y
                  seguridad. Nuestro sitio sigue los más altos estándares y toda
                  la información ingresada se mantiene de manera estrictamente
                  confidencial.
                  <br>
                  <br>
                  Para garantizar la seguridad en los pagos usamos la tecnología
                  SPS de DECIDIR, perteneciente a Prisma.
                  <br>
                  <br>
                  Con respecto a los datos de tu tarjeta de crédito nuestros
                  sistemas se rigen bajo las normas PCI DSS (Estándar de
                  Seguridad de Datos para la Industria de Tarjeta de Pago).
                </span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
      <div class="card-header p-0" id="group-31">
        <h2 class="mb-0">
          <button style="font-size: 12px;"
            class="btn btn-link btn-block text-left text-dark" type="button"
            data-toggle="collapse" data-target="#collapse-31"
            aria-expanded="false" aria-controls="collapse-31">
            {{'¿Cuáles son las medidas de seguridad con las que cuenta Tienda BNA?'
            | uppercase}}
            <i class="fa fa-chevron-down"
              style="opacity:.2; float: right; margin-top: 4px;"></i>
          </button>
        </h2>
      </div>

      <div id="collapse-31" class="collapse" aria-labelledby="group-31">
        <div class="card-body">
          <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
            <span style="font-size:12px">
              <span style="background-color:white">
                <span style="font-family:Tahoma,Geneva,sans-serif">
                  Contamos con la tecnología SSL (Secure Sockets Layer) que
                  asegura tanto la autenticidad de nuestro sitio como el cifrado
                  de toda la información que completó en el sitio. Cada vez que
                  comprás en Tienda BNA y completas informaciones de carácter
                  personal, sin importar dónde te encuentres, tu navegador se
                  conecta a nuestro sitio a través del protocolo SSL que
                  acredita que estás realmente en Tienda BNA y en nuestros
                  servidores (lo cual notarás con la aparición del código HTTPS
                  en la barra de direcciones de tu navegador).
                  <br>
                  <br>
                  De esta forma se establece un método de cifrado de tu
                  información y una clave de sesión única. Esta tecnología
                  permite que información personal, como nombre, dirección y
                  datos de tarjeta de crédito sean codificadas antes de tu
                  transferencia para que no pueda ser leída cuando viaja a
                  través de Internet. Todos los certificados SSL se crean para
                  un servidor particular, en un dominio específico y para una
                  entidad comercial comprobada. Tienda BNA cuenta con un
                  servidor certificado.
                </span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
      <div class="card-header p-0" id="group-32">
        <h2 class="mb-0">
          <button style="font-size: 12px;"
            class="btn btn-link btn-block text-left text-dark" type="button"
            data-toggle="collapse" data-target="#collapse-32"
            aria-expanded="false" aria-controls="collapse-32">
            {{'Estoy interesado en vender productos en la Tienda ¿Qué tengo que hacer?'
            | uppercase}}
            <i class="fa fa-chevron-down"
              style="opacity:.2; float: right; margin-top: 4px;"></i>
          </button>
        </h2>
      </div>

      <div id="collapse-32" class="collapse" aria-labelledby="group-32">
        <div class="card-body">
          <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
            <span style="font-size:12px">
              <span style="background-color:white">
                <span style="font-family:Tahoma,Geneva,sans-serif">
                  Si estas interesado en formar parte de la red de comercios
                  asociados de Tienda BNA podés completar el siguiente
                  formulario para que un asesor especializado se ponga en
                  contacto con vos. Link <a
                    href="https://www.tiendabna.com.ar/vende-con-nosotros"
                    target="_blank">formulario</a>
                </span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
      <div class="card-header p-0" id="group-33">
        <h2 class="mb-0">
          <button style="font-size: 12px;"
            class="btn btn-link btn-block text-left text-dark" type="button"
            data-toggle="collapse" data-target="#collapse-33"
            aria-expanded="false" aria-controls="collapse-33">
            {{'¿Por qué es seguro comprar en Tienda BNA?' | uppercase}}
            <i class="fa fa-chevron-down"
              style="opacity:.2; float: right; margin-top: 4px;"></i>
          </button>
        </h2>
      </div>

      <div id="collapse-33" class="collapse" aria-labelledby="group-33">
        <div class="card-body">
          <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
            <span style="font-size:12px">
              <span style="background-color:white">
                <span style="font-family:Tahoma,Geneva,sans-serif">
                  - Porque los datos que usted ingresa viajan encriptados entre
                  su computadora y PRISMA, gracias a la tecnología de
                  certificados digitales. Para esto, sugerimos que verifique que
                  su navegador le indique la presencia del certificado digital
                  (el candado).
                  <br>
                  <br>
                  - Porque los datos de su tarjeta son tratados siguiendo los
                  más altos estándares de seguridad exigidos por las normas PCI
                  DSS (Estándar de Seguridad de Datos para la Industria de
                  Tarjeta de Pago).
                  <br>
                  <br>
                  - Porque nuestro sistema se encuentra auditado por los medios
                  de pago para asegurar el estricto cumplimiento de las medidas
                  de seguridad.
                  <br>
                  <br>
                  - Porque usted no se ve obligado a brindar los datos sensibles
                  de su tarjeta a un desconocido.
                </span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
      <div class="card-header p-0" id="group-34">
        <h2 class="mb-0">
          <button style="font-size: 12px;"
            class="btn btn-link btn-block text-left text-dark" type="button"
            data-toggle="collapse" data-target="#collapse-34"
            aria-expanded="false" aria-controls="collapse-34">
            {{'¿QUÉ ES BNA + MODO?' | uppercase}}
            <i class="fa fa-chevron-down"
              style="opacity:.2; float: right; margin-top: 4px;"></i>
          </button>
        </h2>
      </div>

      <div id="collapse-34" class="collapse" aria-labelledby="group-34">
        <div class="card-body">
          <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
            <span style="font-size:12px">
              <span style="background-color:white">
                <span style="font-family:Tahoma,Geneva,sans-serif">
                  - BNA + MODO es un servicio cuyo objeto es permitir a los
                  usuarios efectuar pagos escaneando un código QR.
                  <br>
                  <br>
                  - Para más info de BNA+ ingresá <a
                    href="//www.bna.com.ar/Personas/BNAMas/PreguntasFrecuentesBNAmas#:~:text=Verific%C3%A1%20que%20se%20encuentra%20descargada,y%20puedas%20operar%20en%20BNA%2B"
                    target="_blank">acá</a>.
                </span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
      <div class="card-header p-0" id="group-35">
        <h2 class="mb-0">
          <button style="font-size: 12px;"
            class="btn btn-link btn-block text-left text-dark" type="button"
            data-toggle="collapse" data-target="#collapse-35"
            aria-expanded="false" aria-controls="collapse-35">
            {{'¿PUEDO PAGAR CON UNA O MÁS TARJETAS?' | uppercase}}
            <i class="fa fa-chevron-down"
              style="opacity:.2; float: right; margin-top: 4px;"></i>
          </button>
        </h2>
      </div>

      <div id="collapse-35" class="collapse" aria-labelledby="group-35">
        <div class="card-body">
          <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
            <span style="font-size:12px">
              <span style="background-color:white">
                <span style="font-family:Tahoma,Geneva,sans-serif">
                  - Si, podés pagar con una o dos tarjetas ya sean de débito o
                  crédito.
                  <br>
                </span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
      <div class="card-header p-0" id="group-36">
        <h2 class="mb-0">
          <button style="font-size: 12px;"
            class="btn btn-link btn-block text-left text-dark" type="button"
            data-toggle="collapse" data-target="#collapse-36"
            aria-expanded="false" aria-controls="collapse-36">
            {{'¿CÓMO PUEDO PAGAR CON BNA+MODO?' | uppercase}}
            <i class="fa fa-chevron-down"
              style="opacity:.2; float: right; margin-top: 4px;"></i>
          </button>
        </h2>
      </div>

      <div id="collapse-36" class="collapse" aria-labelledby="group-36">
        <div class="card-body">
          <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
            <span style="font-size:12px">
              <span style="background-color:white">
                <span style="font-family:Tahoma,Geneva,sans-serif">
                  - Una vez que selecciones 'Comprar Ahora' para abonar con BNA+
                  MODO deberás presionar en 'Continuar' para generar el código
                  QR y automáticamente se habilitará el pago siguiendo las
                  instrucciones que indica el sistema.
                  <br>
                  <br>
                  - En caso de que se haga la compra desde el celular, no será
                  necesario escanear el QR con otro celular, sino que deberás
                  tener la app MODO en ese dispositivo y automáticamente te
                  dirigirá a la aplicación para pagar.
                </span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
      <div class="card-header p-0" id="group-37">
        <h2 class="mb-0">
          <button style="font-size: 12px;"
            class="btn btn-link btn-block text-left text-dark" type="button"
            data-toggle="collapse" data-target="#collapse-37"
            aria-expanded="false" aria-controls="collapse-37">
            {{'¿PUEDO PAGAR CON BNA+ O MODO CON TARJETAS DE OTRO BANCO?' |
            uppercase}}
            <i class="fa fa-chevron-down"
              style="opacity:.2; float: right; margin-top: 4px;"></i>
          </button>
        </h2>
      </div>

      <div id="collapse-37" class="collapse" aria-labelledby="group-37">
        <div class="card-body">
          <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
            <span style="font-size:12px">
              <span style="background-color:white">
                <span style="font-family:Tahoma,Geneva,sans-serif">
                  - Únicamente se podrá utilizar con dinero en cuenta, tarjeta
                  de débito, y crédito del Banco Nación.
                  <br>
                </span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
      <div class="card-header p-0" id="group-38">
        <h2 class="mb-0">
          <button style="font-size: 12px;"
            class="btn btn-link btn-block text-left text-dark" type="button"
            data-toggle="collapse" data-target="#collapse-38"
            aria-expanded="false" aria-controls="collapse-38">
            {{'¿PUEDO PAGAR CON 2 TARJETAS USANDO BNA+MODO?' | uppercase}}
            <i class="fa fa-chevron-down"
              style="opacity:.2; float: right; margin-top: 4px;"></i>
          </button>
        </h2>
      </div>

      <div id="collapse-38" class="collapse" aria-labelledby="group-38">
        <div class="card-body">
          <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
            <span style="font-size:12px">
              <span style="background-color:white">
                <span style="font-family:Tahoma,Geneva,sans-serif">
                  - No, modo permite únicamente el pagó con una tarjeta o dinero
                  en cuenta.
                  <br>
                </span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
      <div class="card-header p-0" id="group-39">
        <h2 class="mb-0">
          <button style="font-size: 12px;"
            class="btn btn-link btn-block text-left text-dark" type="button"
            data-toggle="collapse" data-target="#collapse-39"
            aria-expanded="false" aria-controls="collapse-39">
            {{'EL QR DE LA WEB NO ABRE ¿QUÉ HAGO?' | uppercase}}
            <i class="fa fa-chevron-down"
              style="opacity:.2; float: right; margin-top: 4px;"></i>
          </button>
        </h2>
      </div>

      <div id="collapse-39" class="collapse" aria-labelledby="group-39">
        <div class="card-body">
          <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
            <span style="font-size:12px">
              <span style="background-color:white">
                <span style="font-family:Tahoma,Geneva,sans-serif">
                  - Si no carga el QR para escanear, sugerimos volver un paso
                  para atrás, y/o refrescar la página e intentarlo nuevamente.
                  <br>
                </span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
      <div class="card-header p-0" id="group-40">
        <h2 class="mb-0">
          <button style="font-size: 12px;"
            class="btn btn-link btn-block text-left text-dark" type="button"
            data-toggle="collapse" data-target="#collapse-40"
            aria-expanded="false" aria-controls="collapse-40">
            {{'QUIERO COMPRAR 2 PRODUCTOS Y PAGAR CON MODO Y NO ME DEJA' |
            uppercase}}
            <i class="fa fa-chevron-down"
              style="opacity:.2; float: right; margin-top: 4px;"></i>
          </button>
        </h2>
      </div>

      <div id="collapse-40" class="collapse" aria-labelledby="group-40">
        <div class="card-body">
          <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
            <span style="font-size:12px">
              <span style="background-color:white">
                <span style="font-family:Tahoma,Geneva,sans-serif">
                  - Si estás intentando comprar dos productos que tienen
                  diferentes opciones de financiación, no podrás abonarlos
                  juntos con BNA+ MODO. Sugerimos realizar una compra por
                  separado, es decir una por cada producto.
                  <br>
                </span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
      <div class="card-header p-0" id="group-41">
        <h2 class="mb-0">
          <button style="font-size: 12px;"
            class="btn btn-link btn-block text-left text-dark" type="button"
            data-toggle="collapse" data-target="#collapse-41"
            aria-expanded="false" aria-controls="collapse-41">
            {{'¿COMO VEO EL IMPACTO DE MI COMPRA CON MODO?' | uppercase}}
            <i class="fa fa-chevron-down"
              style="opacity:.2; float: right; margin-top: 4px;"></i>
          </button>
        </h2>
      </div>

      <div id="collapse-41" class="collapse" aria-labelledby="group-41">
        <div class="card-body">
          <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
            <span style="font-size:12px">
              <span style="background-color:white">
                <span style="font-family:Tahoma,Geneva,sans-serif">
                  - Tu compra se verá reflejada en tu resumen de cuenta, como
                  cualquier otra compra con tus tarjetas.
                  <br>
                </span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
      <div class="card-header p-0" id="group-42">
        <h2 class="mb-0">
          <button style="font-size: 12px;"
            class="btn btn-link btn-block text-left text-dark" type="button"
            data-toggle="collapse" data-target="#collapse-42"
            aria-expanded="false" aria-controls="collapse-42">
            {{'¿QUÉ PASA SI ME ARREPIENTO Y YA PAGUÉ CON MODO?' | uppercase}}
            <i class="fa fa-chevron-down"
              style="opacity:.2; float: right; margin-top: 4px;"></i>
          </button>
        </h2>
      </div>

      <div id="collapse-42" class="collapse" aria-labelledby="group-42">
        <div class="card-body">
          <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
            <span style="font-size:12px">
              <span style="background-color:white">
                <span style="font-family:Tahoma,Geneva,sans-serif">
                  - Si te arrepentiste de la compra debes utilizar el <a
                    href="https://www.tiendabna.com.ar/ayuda" target="_blank">botón
                    de Arrepentimiento</a>, desde minutos después de realizado
                  el pago, hasta 10 días posteriores de haber recibido el
                  producto. La devolución del dinero la veras reflejada en el
                  mismo medio de pago con el que abonaste.
                  <br>
                </span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- End Section -->

    <!-- Section -->
    <div class="card">
      <div class="card-header p-0" id="group-43">
        <h2 class="mb-0">
          <button style="font-size: 12px;"
            class="btn btn-link btn-block text-left text-dark" type="button"
            data-toggle="collapse" data-target="#collapse-43"
            aria-expanded="false" aria-controls="collapse-43">
            {{'COMPRÉ CON MODO Y NO OBTUVE EL BENEFICIO DE DESCUENTO QUE COMUNICABA'
            | uppercase}}
            <i class="fa fa-chevron-down"
              style="opacity:.2; float: right; margin-top: 4px;"></i>
          </button>
        </h2>
      </div>

      <div id="collapse-43" class="collapse" aria-labelledby="group-43">
        <div class="card-body">
          <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
            <span style="font-size:12px">
              <span style="background-color:white">
                <span style="font-family:Tahoma,Geneva,sans-serif">
                  - Por favor comunícate al 0810-666-4444 (Centro de atención al
                  cliente Banco Nación) de lunes a viernes de 8:00 a 20:00 o
                  ingresa <a
                    href="https://ayuda.modo.com.ar/support/solutions/folders/66000112947"
                    target="_blank">acá</a> para que puedan ver tu caso en
                  detalle.
                  <br>
                </span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- End Section -->

  </div>

  <!-- MACRO -->
  <div *ngIf="option === 1" class="accordion">
    <!-- FAQS -->
    <div class="card" *ngFor="let faq of faqs; index as i">
      <div class="card-header p-0" id="group-item-{{i}}">
        <h2 class="mb-0">
          <button style="font-size: 12px;"
            class="btn btn-link btn-block text-left text-dark"
            type="button"
            (click)="toggleCollapse(i)"
            [attr.aria-expanded]="faq.collapse? 'false' : 'true'"
            [attr.aria-controls]="'collapse-item-' + i"
          >
            {{i + 1}}. {{faq.question}}
            <i class="fa fa-chevron-down" style="opacity:.2; float: right; margin-top: 4px;"></i>
          </button>
        </h2>
      </div>

      <div id="collapse-item-{{i}}" [ngClass]="{'collapse': faq.collapse}" aria-labelledby="group-item-{{i}}">
        <div class="card-body">
          <p style="margin-left:0cm; margin-right:0cm; text-align:justify">
            <span style="font-size:12px">
              <span style="background-color:white">
                <span style="color:#333333" [innerHTML]="faq.answer"></span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- End FAQS -->
  </div>
  <!-- End MACRO FAQs -->

  <!-- SPORT CLUB -->
  <ng-container *ngIf="option === 2">
    <div *ngIf="option === 2" class="accordion">
      <h2 style="font-size: 12px" class="mb-0">PARA EL SOCIO DE SPORTCLUB:</h2>
      <br>
      <!-- Section -->
      <div class="card">
        <div class="card-header p-0" id="group-1">
          <h2 class="mb-0">
            <button style="font-size: 12px;"
              class="btn btn-link btn-block text-left text-dark" type="button"
              data-toggle="collapse" data-target="#collapse-1"
              aria-expanded="false" aria-controls="collapse-1">
              ¿Quién puede acceder a los Beneficios de los Comercios Saludables?
              <i class="fa fa-chevron-down"
                style="opacity:.2; float: right; margin-top: 4px;"></i>
            </button>
          </h2>
        </div>

        <div id="collapse-1" class="collapse" aria-labelledby="group-1">
          <div class="card-body">
            <p style="margin-left:0cm; margin-right:0cm"><span
                style="font-size:12px"><span style="background-color:white"><span
                    style="color:#333333">Solo los Socios Activos Vigentes de
                    SportClub.</span></span></span></p>
          </div>
        </div>
      </div>
      <!-- End Section -->

      <!-- Section -->
      <div class="card">
        <div class="card-header p-0" id="group-2">
          <h2 class="mb-0">
            <button style="font-size: 12px;"
              class="btn btn-link btn-block text-left text-dark" type="button"
              data-toggle="collapse" data-target="#collapse-2"
              aria-expanded="false" aria-controls="collapse-1">
              ¿Cómo accedo a los Beneficios de los Comercios Saludables?
              <i class="fa fa-chevron-down"
                style="opacity:.2; float: right; margin-top: 4px;"></i>
            </button>
          </h2>
        </div>

        <div id="collapse-2" class="collapse" aria-labelledby="group-2">
          <div class="card-body">
            <p style="margin-left:0cm; margin-right:0cm"><span
                style="font-size:12px"><span style="background-color:white"><span
                    style="color:#333333">Debes mostrar tu Carnet Digital
                    SportClub en el Comercio para acreditar tu estado de Socio
                    Activo Vigente y el comercio te hará el Descuento
                    correspondiente.</span></span></span></p>
          </div>
        </div>
      </div>
      <!-- End Section -->

      <!-- Section -->
      <div class="card">
        <div class="card-header p-0" id="group-3">
          <h2 class="mb-0">
            <button style="font-size: 12px;"
              class="btn btn-link btn-block text-left text-dark" type="button"
              data-toggle="collapse" data-target="#collapse-3"
              aria-expanded="false" aria-controls="collapse-1">
              ¿Cómo hago para descargar el Carnet Digital SportClub (App)?
              <i class="fa fa-chevron-down"
                style="opacity:.2; float: right; margin-top: 4px;"></i>
            </button>
          </h2>
        </div>

        <div id="collapse-3" class="collapse" aria-labelledby="group-3">
          <div class="card-body">
            <p style="margin-left:0cm; margin-right:0cm"><span
                style="font-size:12px"><span style="background-color:white"><span
                    style="color:#333333">-IOS: <a
                      href="https://apps.apple.com/us/app/sportclub/id1583613615"
                      target="_blank">https://apps.apple.com/us/app/sportclub/id1583613615</a><br><br>-Android:
                    <a
                      href="https://play.google.com/store/apps/details?id=com.muvinai.carnet2"
                      target="_blank">https://play.google.com/store/apps/details?id=com.muvinai.carnet2</a></span></span></span></p>
          </div>
        </div>
      </div>
      <!-- End Section -->

      <!-- Section -->
      <div class="card">
        <div class="card-header p-0" id="group-4">
          <h2 class="mb-0">
            <button style="font-size: 12px;"
              class="btn btn-link btn-block text-left text-dark" type="button"
              data-toggle="collapse" data-target="#collapse-4"
              aria-expanded="false" aria-controls="collapse-1">
              ¿Cómo identifico un Comercio Adherido a Comercios Saludables?
              <i class="fa fa-chevron-down"
                style="opacity:.2; float: right; margin-top: 4px;"></i>
            </button>
          </h2>
        </div>

        <div id="collapse-4" class="collapse" aria-labelledby="group-4">
          <div class="card-body">
            <p style="margin-left:0cm; margin-right:0cm"><span
                style="font-size:12px"><span style="background-color:white"><span
                    style="color:#333333">Los Comercios Adheridos tienen un
                    Sticker pegado en la Vidriera o cuentan con material POP
                    dentro del Comercio.</span></span></span></p>
          </div>
        </div>
      </div>
      <!-- End Section -->

      <!-- Section -->
      <div class="card">
        <div class="card-header p-0" id="group-5">
          <h2 class="mb-0">
            <button style="font-size: 12px;"
              class="btn btn-link btn-block text-left text-dark" type="button"
              data-toggle="collapse" data-target="#collapse-5"
              aria-expanded="false" aria-controls="collapse-1">
              ¿Dónde encuentro los Comercios Adheridos y toda la Información de
              los Beneficios de Cada Comercio?
              <i class="fa fa-chevron-down"
                style="opacity:.2; float: right; margin-top: 4px;"></i>
            </button>
          </h2>
        </div>

        <div id="collapse-5" class="collapse" aria-labelledby="group-5">
          <div class="card-body">
            <p style="margin-left:0cm; margin-right:0cm"><span
                style="font-size:12px"><span style="background-color:white"><span
                    style="color:#333333">En la Landing de Comercios Saludables
                    dentro de la Página Web de SportClub Oficial (<a
                      href="www.sportclub.com.ar">www.sportclub.com.ar</a>)</span></span></span></p>
          </div>
        </div>
      </div>
      <!-- End Section -->

    </div>
    <br>
    <br>
    <div *ngIf="option === 2" class="accordion">
      <!-- <br> -->
      <h2 style="font-size: 12px" class="mb-0">PARA EL COMERCIO:</h2>
      <br>
      <!-- Section -->
      <div class="card">
        <div class="card-header p-0" id="group-6">
          <h2 class="mb-0">
            <button style="font-size: 12px;"
              class="btn btn-link btn-block text-left text-dark" type="button"
              data-toggle="collapse" data-target="#collapse-6"
              aria-expanded="false" aria-controls="collapse-1">
              ¿Que es un Comercio Saludable?
              <i class="fa fa-chevron-down"
                style="opacity:.2; float: right; margin-top: 4px;"></i>
            </button>
          </h2>
        </div>

        <div id="collapse-6" class="collapse" aria-labelledby="group-6">
          <div class="card-body">
            <p style="margin-left:0cm; margin-right:0cm"><span
                style="font-size:12px"><span style="background-color:white"><span
                    style="color:#333333">Los Comercios Saludables son todos
                    aquellos que se suman a la Red de Beneficios SportClub
                    apoyando al Deporte y a la Vida
                    Saludable.<br><br>De esa manera, la Comunidad de SportClub
                    tiene la posibilidad de acceder a un mundo de Beneficios ,
                    Contenidos y Experiencias
                    vinculadas a la comunidad del deporte.<br><br>Sumandote a
                    este programa de Beneficios vas a ser parte de la Comunidad
                    SportClub que cuenta con mas de 200.000 Socios en
                    todo el Pais y que es fiel a la marca desde hace mas de 30
                    años.</span></span></span></p>
          </div>
        </div>
      </div>
      <!-- End Section -->

      <!-- Section -->
      <div class="card">
        <div class="card-header p-0" id="group-7">
          <h2 class="mb-0">
            <button style="font-size: 12px;"
              class="btn btn-link btn-block text-left text-dark" type="button"
              data-toggle="collapse" data-target="#collapse-7"
              aria-expanded="false" aria-controls="collapse-1">
              ¿Cómo adhiero mi Comercio a Comercios Saludables?
              <i class="fa fa-chevron-down"
                style="opacity:.2; float: right; margin-top: 4px;"></i>
            </button>
          </h2>
        </div>

        <div id="collapse-7" class="collapse" aria-labelledby="group-7">
          <div class="card-body">
            <p style="margin-left:0cm; margin-right:0cm;"><span
                style="font-size:12px"><span style="background-color:white"><span
                    style="color:#333333">Para adherir tu Comercio:<br>*Debes
                    contactarte con nosotros a “beneficios@sportclub.com.ar”<br>*Completar
                    y firmar el formulario de adhesión correspondiente<br>*Enviar
                    toda la Info requerida de tu Comercio<br>*Pegar el Sticker
                    correspondiente en tu Comercio<br><br>Una vez adherido tu
                    Establecimiento a la Red de Comercios Saludables de
                    SportClub, el mismo será subido a la Web Oficial de
                    SportClub (dentro de Comercios Saludables) y promocionado en
                    todos los medios de comunicación a nuestros Socios para que
                    todos los usuarios puedan disfrutar y vivir la experiencia
                    del servicio del Comercio.</span></span></span></p>
          </div>
        </div>
      </div>
      <!-- End Section -->

      <!-- Section -->
      <div class="card">
        <div class="card-header p-0" id="group-8">
          <h2 class="mb-0">
            <button style="font-size: 12px;"
              class="btn btn-link btn-block text-left text-dark" type="button"
              data-toggle="collapse" data-target="#collapse-8"
              aria-expanded="false" aria-controls="collapse-1">
              ¿Cuáles son los Beneficios para mi Comercio?
              <i class="fa fa-chevron-down"
                style="opacity:.2; float: right; margin-top: 4px;"></i>
            </button>
          </h2>
        </div>

        <div id="collapse-8" class="collapse" aria-labelledby="group-8">
          <div class="card-body">
            <p style="margin-left:0cm; margin-right:0cm"><span
                style="font-size:12px"><span style="background-color:white"><span
                    style="color:#333333">Siendo parte de la Red de Comercios
                    Saludables de SportClub, accedes a los siguientes
                    beneficios:<br>-Captación de Nuevos Clientes (+200.000
                    Socios de SportClub en todo el Pais)<br>-Posicionamiento y
                    Visibilidad de marca<br>-Aumento de Ingresos Económicos<br>-Aumento
                    de Brand Awareness</span></span></span></p>
          </div>
        </div>
      </div>
      <!-- End Section -->

      <!-- Section -->
      <div class="card">
        <div class="card-header p-0" id="group-9">
          <h2 class="mb-0">
            <button style="font-size: 12px;"
              class="btn btn-link btn-block text-left text-dark" type="button"
              data-toggle="collapse" data-target="#collapse-9"
              aria-expanded="false" aria-controls="collapse-1">
              ¿Qué estas esperando para Adherir tu Comercio?
              <i class="fa fa-chevron-down"
                style="opacity:.2; float: right; margin-top: 4px;"></i>
            </button>
          </h2>
        </div>

        <div id="collapse-9" class="collapse" aria-labelledby="group-9">
          <div class="card-body">
            <p style="margin-left:0cm; margin-right:0cm"><span
                style="font-size:12px"><span style="background-color:white"><span
                    style="color:#333333">-Apoyas a la Comunidad que tiene una
                    Vida Saludable <br>-No requiere ningún tipo de inversión<br>-Además,
                    contas con Beneficios exclusivos para tus Empleados:<br>*Acceso
                    a la Plataforma Online con Clases Online / On Demand<br>*Acceso
                    a la App de Nutrición<br>*Acceso al Programa de Beneficios
                    SportClub</span></span></span></p>
          </div>
        </div>
      </div>
      <!-- End Section -->
    </div>
    <!-- End SPORT CLUB Accodion FAQs -->
  </ng-container>
</div>
